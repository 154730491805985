import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import logo from "../../assets/icons/builds3-2.svg";

const navigation = [
  { name: "properties", to: "/properties" },
  {
    name: "landlords",
    to: "/landlords",
    subPages: [
      { name: "why rent with ward", to: "/landlords" },
      { name: "pricing and plans", to: "/landlords/pricing" },
      /*{ name: "become a landlord", to: "/landlords/how" }, */
    ],
  },
  {
    name: "services",
    to: "/services",
    subPages: [
      { name: "management", to: "/services/property-management" },
      { name: "maintenance", to: "/services/property-maintenance" },
      { name: "marketing", to: "/services/property-marketing" },
    ],
  },
  { name: "contact", to: "/contact" },
];

export default function Navbar({value}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [dropdownTimeout, setDropdownTimeout] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const menuRef = useRef(null);

  // Use useLocation to get current path
  const location = useLocation();

  // Paths where solid background is needed
  const solidBackgroundPages = ["/services"];

  // Check if current path requires a solid background
  const needsSolidBackground =
    solidBackgroundPages.includes(location.pathname) ||
    location.pathname.startsWith("/property/") ||
    location.pathname.startsWith("/supersecretlogin") ||
    value && location.pathname.startsWith("/properties");

  const toggleHamburger = () => {
    setIsOpen((prev) => !prev);
    if (isOpen) {
      setOpenDropdown(null);
    }
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    setOpenDropdown(null);
  };

  const handleDropdownMouseEnter = (index) => {
    if (dropdownTimeout) clearTimeout(dropdownTimeout);
    setOpenDropdown(index);
  };

  const handleDropdownMouseLeave = () => {
    setDropdownTimeout(
      setTimeout(() => {
        setOpenDropdown(null);
      }, 200)
    );
  };

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      if (dropdownTimeout) clearTimeout(dropdownTimeout);
    };
  }, [dropdownTimeout]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (dropdownTimeout) clearTimeout(dropdownTimeout);
    };
  }, [dropdownTimeout]);

  const documentHeight = document.documentElement.scrollHeight;
  const scrollThreshold = documentHeight / 10;
  const opacity = scrollY > scrollThreshold ? 1 : scrollY / scrollThreshold;

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-10 transition-opacity duration-500`}
      style={{
        backgroundColor: needsSolidBackground
          ? "rgba(12, 18, 27, 1)" // Solid background for specific pages
          : `rgba(12, 18, 27, ${opacity})`, // Transparent background for others
        transform: `scale(1)`, // Fixed scale to remove the scaling effect
        transformOrigin: "top",
      }}
    >
      <div className="max-w-7xl mx-auto px-2 py-2 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/">
              <img src={logo} alt="Ward Property" className="h-16 w-auto" /> {/* Increased logo size */}
            </Link>
          </div>
          <div className="hidden sm:flex sm:space-x-4">
            {navigation.map((item, index) => (
              <div
                key={item.name}
                className="relative"
                onMouseEnter={() => handleDropdownMouseEnter(index)}
                onMouseLeave={handleDropdownMouseLeave}
              >
                {item.subPages ? (
                  <button className="text-gray-300 hover:bg-[#0c121b] hover:text-white px-4 py-2 rounded-md flex items-center h-full text-lg"> 
                    {item.name}
                    <span className="ml-2">
                      {openDropdown === index ? "-" : "+"}
                    </span>
                  </button>
                ) : (
                  <Link
                    to={item.to}
                    onClick={handleLinkClick}
                    className="text-gray-300 hover:bg-[#0c121b] hover:text-white px-4 py-2 rounded-md flex items-center h-full text-lg" 
                  >
                    {item.name}
                  </Link>
                )}
                {item.subPages && openDropdown === index && (
                  <motion.div
                    className="absolute left-0 mt-2 w-48 bg-gray-900 shadow-lg rounded-md z-10"
                    initial={{ opacity: 0, maxHeight: 0 }}
                    animate={{ opacity: 1, maxHeight: "300px" }}
                    exit={{ opacity: 0, maxHeight: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    style={{ overflow: "hidden" }}
                  >
                    {item.subPages.map((subPage, subPageIndex) => (
                      <motion.div
                        key={subPage.name}
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{
                          duration: 0.2,
                          delay: subPageIndex * 0.05,
                        }}
                      >
                        <Link
                          to={subPage.to}
                          onClick={handleLinkClick}
                          className="block px-4 py-2 text-sm text-gray-300 hover:bg-[#0c121b] hover:text-white"
                        >
                          {subPage.name}
                        </Link>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </div>
            ))}
          </div>
          <div className="sm:hidden">
            <button
              onClick={toggleHamburger}
              className="text-gray-400 focus:outline-none"
            >
              {isOpen ? (
                <XMarkIcon className="w-6 h-6" />
              ) : (
                <Bars3Icon className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <motion.div
          ref={menuRef}
          className="sm:hidden"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item, index) => (
              <div key={item.name} className="relative">
                {item.subPages ? (
                  <>
                    <button
                      onClick={() => handleDropdownToggle(index)}
                      className="block text-gray-300 rounded-md px-3 py-2 text-base font-medium flex justify-between items-center w-full"
                    >
                      {item.name}
                      <span>{openDropdown === index ? "-" : "+"}</span>
                    </button>
                    {openDropdown === index && (
                      <motion.div
                        className="space-y-1"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {item.subPages.map((subPage) => (
                          <Link
                            key={subPage.name}
                            to={subPage.to}
                            onClick={handleLinkClick}
                            className="block text-gray-300 hover:bg-[#0c121b] hover:text-white rounded-md px-5 py-2"
                          >
                            {subPage.name}
                          </Link>
                        ))}
                      </motion.div>
                    )}
                  </>
                ) : (
                  <Link
                    to={item.to}
                    onClick={handleLinkClick}
                    className="block text-gray-300 hover:bg-[#0c121b] hover:text-white rounded-md px-3 py-2"
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </nav>
  );
}
